import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 8rem auto;
  ${MEDIA.DESKTOP`
    display: block;
  `};
`;
