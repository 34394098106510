import React from 'react';
import PropTypes from 'prop-types';

import { Wrapper, Text } from './contactBtn.css';

const Button = ({ content }) => (
  <Wrapper>
    <Text>{content}</Text>
  </Wrapper>
);

Button.propTypes = {
  content: PropTypes.string.isRequired,
};

export default Button;
