import React from 'react';
// import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { Wrapper, Text } from './contactUs.css';
import PageContainer from 'components/pageContainer';
import Button from 'components/contactBtn';

const ContactUs = () => (
  <Wrapper>
    <PageContainer>
      <Text>Get in touch now for a chat about your thatching needs</Text>
      <Link to="/contact">
        <Button content="Contact Us" />
      </Link>
    </PageContainer>
  </Wrapper>
);

// ContactUs.propTypes = {
//   items: PropTypes.arrayOf(PropTypes.object).isRequired,
//   content: PropTypes.string.isRequired,
// };

export default ContactUs;
