import styled, { css } from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';

export const Wrapper = styled.div`
  color: #fff;
  margin: 3em;

  ${({ align }) =>
    align === 'right' &&
    css`
      margin-left: auto;
    `}
  ${MEDIA.DESKTOP`
    margin: 0 auto 2em;
  `}
  a {
    color: #757575;
    transition: color 0.2s ease;
    text-decoration: none;

    &:hover {
      color: inherit;
    }
  }
`;

export const Text = styled.h3`
  font-size: 1.3em;
  text-align: right;
  letter-spacing: 0.3px;
  ${MEDIA.DESKTOP`
    text-align: center;
    line-height: 1.5;
    font-size: 1.2em;
  `}
`;

export const Header = styled.h2`
  font-size: 3.5em;
  text-align: center;
  line-height: 1.7;
  font-weight: 700;
  letter-spacing: 0.3px;
  ${MEDIA.DESKTOP`
    text-align: center;
    line-height: 1.2;
    margin-bottom: 0.5em;
    font-size: 3em;
  `}
  }
`;

export const HorizontalLine = styled.div`
  width: 100%;
  height: 2px;
  margin: 1em 0;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+34,ffffff+100&0+34,1+56,1+100 */
  ${MEDIA.DESKTOP`
    background: white;
  `};
  background: -moz-linear-gradient(
    left,
    rgba(255, 255, 255, 0) 34%,
    rgba(255, 255, 255, 1) 56%,
    rgba(255, 255, 255, 1) 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    left,
    rgba(255, 255, 255, 0) 34%,
    rgba(255, 255, 255, 1) 56%,
    rgba(255, 255, 255, 1) 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 34%,
    rgba(255, 255, 255, 1) 56%,
    rgba(255, 255, 255, 1) 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=1 ); /* IE6-9 */
`;
