import styled, { css } from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';
import Img from 'gatsby-image';
// import colors from '../../../constants/theme';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 4em 0 0;
  width: 960px;
  ${MEDIA.DESKTOP`
    width: 100%;
    margin: 2em 0 0;
  `};
`;

export const Copy = styled.p`
  line-height: 1.6;
  ${MEDIA.TABLET`
    margin-bottom: 4rem;
    font-size: 1.5rem;
  `};
`;

export const StyledFigure = styled.div`
  display: flex;
  margin: 4em 0 2em;
  ${({ reverse }) =>
    reverse &&
    css`
      flex-direction: row-reverse;
    `};
  ${MEDIA.DESKTOP`
    display: block;
  `};
`;

export const StyledFigCaption = styled.figcaption`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: ${({ reverse }) => (reverse ? '0 3em 0 0' : '0 0 0 3em')};
  ${MEDIA.DESKTOP`
    padding: 3rem;
  `};
`;

export const StyledImg = styled(Img)`
  width: 350px;
  margin: 0 auto;
  /* ${MEDIA.DESKTOP`
    width: 90%;
  `}; */
`;
