import styled from 'styled-components';
import colors from 'constants/theme';

export const Wrapper = styled.div`
  /* color: white; */
  width: 180px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid ${colors.color1};
  transition: background ease-in-out 0.1s;
  background: transparent;
  color: ${colors.color1};
  border-radius: 5px;
  &:hover {
    cursor: pointer;
    background: ${colors.color1};
    color: #fff;
    /* color: rgba(51, 51, 51); */
    border: 2px solid ${colors.color1};
`;

export const Text = styled.p`
  font-weight: 600;
  margin-bottom: 0;
`;
