import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';
import colors from 'constants/theme';

export const Wrapper = styled.div`
  /* background: ${colors.color2}; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 6em 0;
  /* width: 100%; */
  ${MEDIA.DESKTOP`
    padding: 4em 2em 4em; 
  `};
  a {
    color: #757575;
    transition: color 0.2s ease;
    text-decoration: none;

    &:hover {
      color: inherit;
    }
  }
`;

export const Text = styled.p`
  font-size: 2em
  line-height: 1.5em;
  font-weight: 600;
  margin: 1.5em 0;
  text-align: center;
  ${MEDIA.DESKTOP`
    line-height: 1.4;
  `};
`;
