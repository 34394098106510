import React from 'react';
import PropTypes from 'prop-types';
import SectionHeader from 'components/sectionHeader/sectionHeader';

import {
  Copy,
  Container,
  StyledFigure,
  StyledImg,
  StyledFigCaption,
} from './item.css';

const Item = ({ title, description, image, reverse }) => (
  <Container>
    <SectionHeader>{title}</SectionHeader>
    <StyledFigure reverse={reverse}>
      <StyledImg fluid={image ? image.childImageSharp.fluid : {}} alt={title} />
      <StyledFigCaption reverse={reverse}>
        <Copy>{description.childMarkdownRemark.rawMarkdownBody}</Copy>
      </StyledFigCaption>
    </StyledFigure>
  </Container>
);

Item.propTypes = {
  title: PropTypes.string,
  description: PropTypes.object,
  image: PropTypes.object.isRequired,
  reverse: PropTypes.bool,
};

export default Item;
