import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from 'components/layout';
import PageContainer from 'components/pageContainer';
// import AboutContainer from 'components/aboutContainer';
import Head from 'components/head';
import ServicesGallery from 'components/servicesGallery';
import Footer from 'components/footer';
import Box from 'components/box';
import BackgroundImage from 'gatsby-background-image';
import Intro from 'components/intro';
import ContactUs from 'components/contactUs';

const Services = ({ data }) => {
  const backgroundFluidImageStack = [
    data.servicesJson.background.image.childImageSharp.fluid,
    'linear-gradient(180deg, rgba(0,0,0,0.3) 0%, rgba(0,0,0,0.3) 100%)',
  ].reverse();
  return (
    <Layout>
      <Head pageTitle={data.servicesJson.pageTitle} />
      <Box>
        <BackgroundImage
          Tag="section"
          className="services"
          fluid={backgroundFluidImageStack}
        >
          <Intro header={data.servicesJson.pageTitle} />
        </BackgroundImage>
      </Box>
      {/* <AboutContainer> */}
      <PageContainer>
        <ServicesGallery items={data.servicesJson.content} />
      </PageContainer>
      {/* </AboutContainer> */}
      <ContactUs />
      <Footer />
    </Layout>
  );
};

Services.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Services;

export const query = graphql`
  query ServicesQuery {
    servicesJson {
      pageTitle
      background {
        image {
          childImageSharp {
            fluid(maxWidth: 1200, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      content {
        title
        image {
          childImageSharp {
            fluid(maxHeight: 300, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        description {
          childMarkdownRemark {
            html
            rawMarkdownBody
          }
        }
      }
    }
  }
`;
