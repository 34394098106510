import React from 'react';
import PropTypes from 'prop-types';

import { Wrapper, Text, Header } from './intro.css';

const Intro = ({ header, content, align }) => (
  <Wrapper align={align}>
    {header && <Header>{header}</Header>}
    {content && <Text>{content}</Text>}
  </Wrapper>
);

Intro.propTypes = {
  content: PropTypes.string,
  header: PropTypes.string,
  align: PropTypes.string,
};

export default Intro;
